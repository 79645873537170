import React from 'react'

interface IProps {
  button: any
  /** Display the Button and Box joined together seamlessly */
  unified?: boolean
}

/**
 * Dropdown
 * Component that toggles visibility of its children when the button
 * prop element is clicked.
 *
 * Requires two props:
 * - button: Button to click (must be a <button /> element)
 * - children: Dropdown contents to show/hide
 */
export default class Dropdown extends React.Component<IProps> {
  el: any = null
  clickListener = null

  componentDidMount() {
    if (this.el) {
      let bttn = this.el.getElementsByTagName('BUTTON')[0]
      this.clickListener = bttn.addEventListener('click', this.handleClick, false)
    }
  }

  componentWillUnmount() {
    if (this.el) {
      let bttn = this.el.getElementsByTagName('BUTTON')[0]
      bttn.removeEventListener('click', this.handleClick)
    }
  }

  handleClick = (ev: any) => {
    ev.preventDefault()
    let dropdownContainerEl = ev.target.parentNode.closest('.Dropdown')
    let bttn = this.el.getElementsByTagName('BUTTON')[0]
    dropdownContainerEl.classList.toggle('is-active')
    bttn.classList.toggle('is-active')
  }

  render() {
    const { button, children, unified } = this.props
    let className = 'Dropdown'

    if (unified) {
      className += ' -unified'
    }

    return (
      <div ref={(el) => (this.el = el)} className={className}>
        {button}
        {children}
      </div>
    )
  }
}
