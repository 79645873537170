import React, { Component } from 'react'

import { formatCurrency } from '@utilities/numbers'
import Dropdown from '@components/Dropdown'
import RepresentativeExample from '@components/RepresentativeExample'

import calculateDerivedValues from '../tenant_illustrative_agreement/calculateDerivedValues'
import styles from './App.module.scss'

const propertyPurchasePrice = 500000
const legalAndSurveyCosts = 2500
const onStepFees = 2500
const repairProvisions = 0
const tenantEquityLoan = 25000
const fixedFirstChargeInvestment = 350000
const housePriceIncreaseScenario = 690000
const housePriceDecreaseScenario = 450000

const illustrationValues = {
  propertyAddress: '',
  companyName: '',
  propertyPurchasePrice,
  legalAndSurveyCosts,
  onStepFees,
  repairProvisions,
  fixedFirstChargeInvestment,
  tenantEquityLoan,
  housePriceIncreaseScenario,
  housePriceDecreaseScenario
}

class RepresentativeExample5PercentDeposit extends Component {

  render() {

    const {
      // Derived values (Property)
      stampDuty,
      propertyPurchaseTotal,

      // Derived values (Tenant)
      tenantPercentageShareOfHouseValue,

      // Derived values (Crowd Equity Loan)
      crowdEquityPercentageShareOfHouseValue,
      crowdEquityInitialInvestment

    } = calculateDerivedValues(illustrationValues)

    const tenantIncreaseScenarioReturn = ((tenantEquityLoan / (propertyPurchaseTotal - fixedFirstChargeInvestment)) * (housePriceIncreaseScenario - propertyPurchaseTotal)) + tenantEquityLoan

    return (
      <ul className="Columns">

        <li className={styles.rep_ex_chart_col}>
          <RepresentativeExample
            labels={[
              {
                highlight: true,
                label: 'Your equity loan',
                value: formatCurrency(tenantEquityLoan, 0),
                subline: `${tenantPercentageShareOfHouseValue}% share of value`
              },
              {
                label: 'OnStep equity loan',
                value: formatCurrency(crowdEquityInitialInvestment, 0),
                subline: `${crowdEquityPercentageShareOfHouseValue}% share of value`
              },
              {
                label: 'First charge mortgage',
                value: formatCurrency(fixedFirstChargeInvestment, 0)
              }
            ]}
            propertyPurchaseTotal={propertyPurchaseTotal}
            tenantEquityLoan={tenantEquityLoan}
            crowdEquityInitialInvestment={crowdEquityInitialInvestment}
            fixedFirstChargeInvestment={fixedFirstChargeInvestment}
          />
        </li>

        <li className={styles.rep_ex_aside_column}>
          <Dropdown button={<button className="Button">{formatCurrency(propertyPurchaseTotal, 0)} (inc. taxes & costs)</button>}>
            <div className="Card -mod-margin-bottom">
              <table className="Table -small">
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td>{formatCurrency(propertyPurchaseTotal, 0)}</td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr>
                    <th>Purchase price</th>
                    <td>{formatCurrency(propertyPurchasePrice, 0)}</td>
                  </tr>
                  <tr>
                    <th>Stamp duty</th>
                    <td>{formatCurrency(stampDuty, 0)}</td>
                  </tr>
                  <tr>
                    <th>Onstep fees</th>
                    <td>{formatCurrency(onStepFees, 0)}</td>
                  </tr>
                  <tr>
                    <th>Legal and surveys</th>
                    <td>{formatCurrency(legalAndSurveyCosts, 0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Dropdown>

          <div className="-mod-margin-bottom-2">
            <div className="Card">
              <h3 className="Heading -four -mod-margin-bottom">Your share of returns</h3>
              <div className="Text">
                <p>You will receive {tenantPercentageShareOfHouseValue}% of any increase in the value of the house.</p>
                <p>
                  If the home is sold for {formatCurrency(housePriceIncreaseScenario, 0)} after 7 years <sup>1</sup> you will
                  receive {formatCurrency(tenantIncreaseScenarioReturn, 0)} (2x of initial investment).
                </p>
                <p>
                  <a className="Button -secondary -mod-margin-top" href="/how-it-works/#repayments">
                    See how repayments work
                  </a>
                </p>
              </div>
            </div>

            <div className="Text -small -mod-margin-top">
              <p>
                <sup>1</sup> Based on published house price index growth in England over the 7 years between Apr&apos;13 to Mar&apos;20.
              </p>
            </div>

          </div>

        </li>

      </ul>
    )
  }
}

class RepresentativeExample10PercentDeposit extends Component {
  render() {
    const propertyPurchasePrice = 500000
    const legalAndSurveyCosts = 2500
    const onStepFees = 2500
    const repairProvisions = 0
    const tenantEquityLoan = 50000
    const fixedFirstChargeInvestment = 350000
    const housePriceIncreaseScenario = 690000
    const housePriceDecreaseScenario = 450000

    const illustrationValues = {
      propertyAddress: '',
      companyName: '',
      propertyPurchasePrice,
      legalAndSurveyCosts,
      onStepFees,
      repairProvisions,
      fixedFirstChargeInvestment,
      tenantEquityLoan,
      housePriceIncreaseScenario,
      housePriceDecreaseScenario
    }
    const {
      // Derived values (Property)
      stampDuty,
      propertyPurchaseTotal,

      // Derived values (Tenant)
      tenantPercentageShareOfHouseValue,

      // Derived values (Crowd Equity Loan)
      crowdEquityPercentageShareOfHouseValue,
      crowdEquityInitialInvestment

    } = calculateDerivedValues(illustrationValues)

    const tenantIncreaseScenarioReturn = ((tenantEquityLoan / (propertyPurchaseTotal - fixedFirstChargeInvestment)) * (housePriceIncreaseScenario - propertyPurchaseTotal)) + tenantEquityLoan

    return (
      <ul className="Columns">

        <li className={styles.rep_ex_chart_col}>
          <RepresentativeExample
            labels={[
              {
                highlight: true,
                label: 'Your equity loan',
                value: formatCurrency(tenantEquityLoan, 0),
                subline: `${tenantPercentageShareOfHouseValue}% share of value`
              },
              {
                label: 'OnStep equity loan',
                value: formatCurrency(crowdEquityInitialInvestment, 0),
                subline: `${crowdEquityPercentageShareOfHouseValue}% share of value`
              },
              {
                label: 'First charge mortgage',
                value: formatCurrency(fixedFirstChargeInvestment, 0)
              }
            ]}
            propertyPurchaseTotal={propertyPurchaseTotal}
            tenantEquityLoan={tenantEquityLoan}
            crowdEquityInitialInvestment={crowdEquityInitialInvestment}
            fixedFirstChargeInvestment={fixedFirstChargeInvestment}
          />
        </li>

        <li className={styles.rep_ex_aside_column}>
          <Dropdown button={<button className="Button">{formatCurrency(propertyPurchaseTotal, 0)} (inc. taxes & costs)</button>}>
            <div className="Card -mod-margin-bottom">
              <table className="Table -small">
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td>{formatCurrency(propertyPurchaseTotal, 0)}</td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr>
                    <th>Purchase price</th>
                    <td>{formatCurrency(propertyPurchasePrice, 0)}</td>
                  </tr>
                  <tr>
                    <th>Stamp duty</th>
                    <td>{formatCurrency(stampDuty, 0)}</td>
                  </tr>
                  <tr>
                    <th>Onstep fees</th>
                    <td>{formatCurrency(onStepFees, 0)}</td>
                  </tr>
                  <tr>
                    <th>Legal and surveys</th>
                    <td>{formatCurrency(legalAndSurveyCosts, 0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Dropdown>

          <div className="-mod-margin-bottom-2">
            <div className="Card">
              <h3 className="Heading -four -mod-margin-bottom">Your share of returns</h3>
              <div className="Text">
                <p>
                  You will receive {tenantPercentageShareOfHouseValue}% of any increase in the value of the house.
                </p>
                <p>
                  If the home is sold for {formatCurrency(housePriceIncreaseScenario, 0)} after 7 years, you will receive {formatCurrency(tenantIncreaseScenarioReturn, 0)} (2x of initial investment).
                </p>
                <p>
                  <a className="Link -secondary" href="/how-it-works/#repayments">
                    See how repayments work
                  </a>
                </p>
              </div>
            </div>

            <div className="Text -small -mod-margin-top">
              <p>
                <sup>1</sup> Based on published house price index growth in England over the 7 years between Apr&apos;13 to Mar&apos;20.
              </p>
            </div>

          </div>

        </li>

      </ul>
    )
  }
}

export default class App extends Component {
  render() {
    return (
      <div>
        <div className="-mod-margin-bottom-2">
          <h3 className="Heading -five">
            Illustrative funding for a £500,000 house
          </h3>
          <h3 className="Heading -four -mod-margin-bottom">
            £520<span className="-mod-gt-mobile">,000</span>
            <span className="-mod-lt-mobile">k</span> incl. taxes & costs
          </h3>

          <RepresentativeExample5PercentDeposit />

        </div>
      </div>
    )
  }
}
