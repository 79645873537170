
/*
  calculateDerivedValues
*/

import { IIllustrationValues } from './types'

export function calculateStampDuty(propertyPurchasePrice: number) {
  // needs to be consistent with Python code in borrowers/constants.py
  const stampDutyLowerBound = 500000
  const stampDutyRate = 0.05
  const stampDutySurcharge = 0.03
  const threePercentTotalPrice = propertyPurchasePrice * stampDutySurcharge
  const amountExceedingThreshold = Math.max(propertyPurchasePrice - stampDutyLowerBound, 0)

                                   // 5% amount exceeding threshold 
  return threePercentTotalPrice + (amountExceedingThreshold * stampDutyRate)
}

export function calculatePropertyPurchaseCostsTotal(legalAndSurveyCosts: number, onStepFees: number, stampDuty: number) {
  return legalAndSurveyCosts + onStepFees + stampDuty
}

interface ICalculatePropertyPurchaseTotalInputs {
  propertyPurchasePrice: number
  legalAndSurveyCosts: number
  onStepFees: number
  stampDuty: number
  repairProvisions: number
}

export function calculatePropertyPurchaseTotal(inputs: ICalculatePropertyPurchaseTotalInputs) {
  const purchaseCostsTotal = calculatePropertyPurchaseCostsTotal(inputs.legalAndSurveyCosts, inputs.onStepFees, inputs.stampDuty)
  return inputs.propertyPurchasePrice + purchaseCostsTotal + inputs.repairProvisions
}

export default function calculateDerivedValues(values: IIllustrationValues) {
  const {
    propertyPurchasePrice,
    legalAndSurveyCosts,
    onStepFees,
    repairProvisions,
    fixedFirstChargeInvestment,
    tenantEquityLoan,
    housePriceIncreaseScenario,
    housePriceDecreaseScenario
  } = values

  // Derived values (Property)
  const stampDuty = calculateStampDuty(propertyPurchasePrice)
  const purchaseCostsTotal = calculatePropertyPurchaseCostsTotal(legalAndSurveyCosts, onStepFees, stampDuty)
  const propertyPurchaseTotal = propertyPurchasePrice + purchaseCostsTotal + repairProvisions
  const sharedEquityValue = (propertyPurchaseTotal - fixedFirstChargeInvestment)

  // Derived values (Tenant)
  const tenantPercentageShareOfHouseValue = Math.round((tenantEquityLoan / sharedEquityValue) * 100)

  // Derived values (Crowd Equity Loan)
  const crowdEquityPercentageShareOfHouseValue = 100 - tenantPercentageShareOfHouseValue
  const crowdEquityInitialInvestment = propertyPurchaseTotal - tenantEquityLoan - fixedFirstChargeInvestment

  // Derived values (House Prices Increased)
  const increaseScenarioPropertyPriceIncrease = housePriceIncreaseScenario - propertyPurchaseTotal
  const increaseScenarioTenantInvestmentIncrease = Math.round((tenantEquityLoan / sharedEquityValue) * increaseScenarioPropertyPriceIncrease)
  const increaseScenarioCrowdEquityInvestmentIncrease = increaseScenarioPropertyPriceIncrease - increaseScenarioTenantInvestmentIncrease
  const increaseScenarioTenantInvestmentTotal = tenantEquityLoan + increaseScenarioTenantInvestmentIncrease
  const increaseScenarioCrowdEquityInvestmentTotal = crowdEquityInitialInvestment + increaseScenarioCrowdEquityInvestmentIncrease

  // Derived values (House Prices Decreased)
  const decreaseScenarioFixedFirstChargeInvestment = Math.min(fixedFirstChargeInvestment, housePriceDecreaseScenario)
  const decreaseScenarioPropertyPriceDecrease = propertyPurchaseTotal - housePriceDecreaseScenario
  const decreaseScenarioTenantInvestmentDecrease = Math.round((tenantEquityLoan / sharedEquityValue) * decreaseScenarioPropertyPriceDecrease)
  const decreaseScenarioCrowdEquityInvestmentDecrease = decreaseScenarioPropertyPriceDecrease - decreaseScenarioTenantInvestmentDecrease
  const decreaseScenarioTenantInvestmentTotal = Math.max(tenantEquityLoan - decreaseScenarioTenantInvestmentDecrease,0)
  const decreaseScenarioCrowdEquityInvestmentTotal = Math.max(crowdEquityInitialInvestment - decreaseScenarioCrowdEquityInvestmentDecrease,0)

  return {

    // Derived values (Property)
    stampDuty,
    propertyPurchaseTotal,

    // Derived values (Tenant)
    tenantPercentageShareOfHouseValue,

    // Derived values (Crowd Equity Loan)
    crowdEquityPercentageShareOfHouseValue,
    crowdEquityInitialInvestment,

    // Derived values (House Prices Increased)
    increaseScenarioPropertyPriceIncrease,
    increaseScenarioTenantInvestmentIncrease,
    increaseScenarioTenantInvestmentTotal,
    increaseScenarioCrowdEquityInvestmentTotal,

    // Derived values (House Prices Decreased)
    decreaseScenarioFixedFirstChargeInvestment,
    decreaseScenarioPropertyPriceDecrease,
    decreaseScenarioTenantInvestmentDecrease,
    decreaseScenarioTenantInvestmentTotal,
    decreaseScenarioCrowdEquityInvestmentTotal,

  }

}
