/**
 * Returns the given number as a string formatted as a currency.
 * 150.555555555 will become '£150.56'.
 *
 * If the value is not a number, then it is returned unaltered.
 */
export function formatCurrency(value: number, decimals: number = 2) {
  if (typeof value === 'number') {
    return value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: decimals
    })
  }
  return value
}

/**
 * Returns the given factor (with 4 decimal places) as a percentage.
 * 0.0400 will become '4.00%'.
 *
 * If the value is not a number, then it is returned unaltered.
 */
export function formatPercentage(value: number, decimals: number = 2) {
  if (typeof value === 'number') {
    return (value * 100.0).toFixed(decimals) + '%'
  }
  return value
}
