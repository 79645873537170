import React from 'react'

interface ILabel {
  label: string
  value: string
  subline?: string
  highlight?: boolean
}

interface IProps {
  labels: ILabel[]
  propertyPurchaseTotal: number
  tenantEquityLoan: number
  crowdEquityInitialInvestment: number
  fixedFirstChargeInvestment: number
}

function Label(props: { label: ILabel, row: number }) {
  const { label, row } = props

  return (
    <>
      <li className={`RepresentativeExample-row${row}-col1`}>
        {label.highlight
          ? <strong>{label.label}</strong>
          : label.label}
      </li>
      <li className={`RepresentativeExample-row${row}-col2`}>
        {label.highlight
          ? <strong>{label.value}</strong>
          : label.value}
        {label.subline ? (
          <div className="-mod-text-small">
            {label.highlight
              ? <strong><em>{label.subline}</em></strong>
              : <em>{label.subline}</em>}
          </div>
        ) : null}
      </li>
    </>
  )
}

export default function RepresentativeExample(props: IProps) {
  const {
    labels,
    propertyPurchaseTotal,
    tenantEquityLoan,
    crowdEquityInitialInvestment,
    fixedFirstChargeInvestment,
  } = props

  // Bar chart
  const repExBarChartHeight = 505  // Height of bar chart
  const tenantShareBar = (tenantEquityLoan / propertyPurchaseTotal) * repExBarChartHeight
  const crowdShareBar = (crowdEquityInitialInvestment / propertyPurchaseTotal) * repExBarChartHeight
  const fixedShareBar = (fixedFirstChargeInvestment / propertyPurchaseTotal) * repExBarChartHeight

  // Labels
  let tenantShareLabelHeight = tenantShareBar
  let crowdShareLabelHeight = crowdShareBar
  let fixedShareLabelHeight = fixedShareBar

  // Give tenant label a min height (to prevent overlap with crowd equity label)
  if (tenantShareLabelHeight < 100) {
    tenantShareLabelHeight = 100
    crowdShareLabelHeight = crowdShareBar - (100 - tenantShareBar)
  }

  if (crowdShareLabelHeight < 100) {
    crowdShareLabelHeight = 100
    fixedShareLabelHeight = fixedShareBar - (100 - fixedShareBar)
  }

  // Rows for the RepresentativeExample labels
  let repExLabelRows = `${tenantShareLabelHeight}px ${crowdShareLabelHeight}px ${fixedShareLabelHeight}px`

  return (
    <div className="RepresentativeExample">
      <div className="RepresentativeExample-image">
        <picture className="Picture">
          <img
            className="Image"
            src="/static/images/home/townhouse.svg"
            alt="Townhouse illustration"
          />
        </picture>
      </div>

      <div className="RepresentativeExample-chart">
        <ul>
          <li
            style={{
              height: `${tenantShareBar}px`
            }}
          ></li>
          <li
            style={{
              height: `${crowdShareBar}px`
            }}
          ></li>
          <li
            style={{
              height: `${fixedShareBar}px`
            }}
          ></li>
        </ul>
      </div>

      <ul className="RepresentativeExample-labels" style={{ height: repExBarChartHeight, gridTemplateRows: repExLabelRows, msGridRows: repExLabelRows }}>

        {/* Row 1 (Your Equity) */}
        <Label label={labels[0]} row={1} />

        {/* Row 2 (Shared Equity) */}
        <Label label={labels[1]} row={2} />

        {/* Row 3 (Fixed Loan LTV) */}
        <Label label={labels[2]} row={3} />

      </ul>
    </div>
  )
}
