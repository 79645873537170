/**
 * @module apps/representative_example_tenant
 */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import App from './App'

class Main extends Component {
  render() {
    return <App />
  }
}

ReactDOM.render(<Main />, document.getElementById('representative_example_tenant'))
